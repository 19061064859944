const Tdata = [
    {
      cover: "./assets/top/category-1.png",
      para: "headphone",
      desc: "3k orders this week",
    },
    {
      cover: "./assets/top/category-2.png",
      para: "watch",
      desc: "4k orders this week",
    },
    {
      cover: "./assets/top/category-3.png",
      para: "sunglass",
      desc: "6k orders this week",
    },
    {
      cover: "./assets/top/category-4.webp",
      para: "sunglass",
      desc: "6k orders this week",
    },
    {
      cover: "./assets/top/category-5.webp",
      para: "sunglass",
      desc: "6k orders this week",
    },
    {
      cover: "./assets/top/category-6.webp",
      para: "sunglass",
      desc: "6k orders this week",
    },
    {
      cover: "./assets/top/category-7.webp",
      para: "sunglass",
      desc: "6k orders this week",
    },
    {
      cover: "./assets/top/category-8.webp",
      para: "sunglass",
      desc: "6k orders this week",
    },
  ]
  
  export default Tdata
