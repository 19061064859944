const Ndata = [
    {
      cover: "./assets/arrivals/arrivals1.png",
      name: "Sunglass",
      price: "$150",
    },
    {
      cover: "./assets/arrivals/arrivals2.png",
      name: "Makeup",
      price: "$250",
    },
    {
      cover: "./assets/arrivals/arrivals3.png",
      name: "Smart Watch",
      price: "$50",
    },
    {
      cover: "./assets/arrivals/arrivals4.png",
      name: "Lipstick",
      price: "$15",
    },
    {
      cover: "./assets/arrivals/arrivals5.png",
      name: "Green Plant",
      price: "$10",
    },
    {
      cover: "./assets/arrivals/arrivals6.png",
      name: "Bonsai tree",
      price: "$400",
    },
  ]
  
  export default Ndata