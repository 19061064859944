const Sdata = [
    {
      id: 1,
      cover: "./assets/SlideCard/slide-1.jpg",
    },
    {
      id: 2,
      cover: "./assets/SlideCard/slide-2.jpg",
    },
    {
      id: 3,
      cover: "./assets/SlideCard/slide-3.jpg",
    },
    {
      id: 4,
      cover: "./assets/SlideCard/slide-4.jpg",
    },
    {
      id: 5,
      cover: "./assets/SlideCard/slide-5.jpg",
    },
  ]
  export default Sdata